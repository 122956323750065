.header .logo {
  -webkit-print-color-adjust: exact;//:For Chrome
  color-adjust: exact;//:For Firefox
}
.navbar-brand {
  display: inline-block;
  width: 45%;
}
.navbar-item.contact-item {
  display: inline-flex;
  vertical-align: text-bottom;
  width: 50%;
}

.header .contact-item .phone:before, .navbar-end.contact-item, .navbar-burger, .print, .product-gallery, .navbar-end, .contact-popup, .breadcrumb, .slick-arrow, .info .button, .short-chars, #product .icons,  footer,.products-block, .partners-block, #feedback-form, .tabs {
  display: none !important;
}

#product .tabs-content > {
  #description, #features, #materials, #compatibility {
    display: block;
    margin: 2rem 0;
  }
}
.product-info {
  width: 100%;
}
